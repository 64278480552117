export default [
    {
      header: 'Dashboard',
      title: 'Dashboard',
      icon: 'HomeIcon',
      route: 'admin-dashboard',
      resource: 'AdminAccess',
    //   children: [
    //     {
    //       title: 'eCommerce',
    //       route: 'dashboard-ecommerce',
    //       icon: 'ShoppingCartIcon',
    //     },
    //     {
    //       title: 'Analytics',
    //       route: 'dashboard-analytics',
    //       icon: 'ActivityIcon',
    //     },
    //   ],
    },
  ]
  