export default [
    {
      header: 'Users',
      title: 'Users',
      icon: 'UsersIcon',
      route: 'users-list',
      resource: 'AdminAccess',

    //   children: [
    //     {
    //       title: 'eCommerce',
    //       route: 'dashboard-ecommerce',
    //       icon: 'ShoppingCartIcon',
    //     },
    //     {
    //       title: 'Analytics',
    //       route: 'dashboard-analytics',
    //       icon: 'ActivityIcon',
    //     },
    //   ],
    },
  ]
  